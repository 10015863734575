body,
html {
    padding: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //-webkit-tap-highlight-color: transparent;
    color: #333;
    font-size: 14px;
    margin: 0;
}

a {
    color: #1122D8;
    text-decoration: none;
}

a:focus,
a:hover {
    color: #5864e4;
}

.clearfix {
    zoom: 1;
}

.clearfix:after {
    content: '';
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}

.text-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-overflow-2 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.pager-center {
    text-align: center;
}

.pager-right {
    text-align: right;
}

.flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-column {
    flex-direction: column;
}

//start 总管理员 tab全屏
.tabs-full {
    display: flex;
    flex-direction: column;
    .el-tabs__header {
        margin-bottom: 0;
        border-bottom: none;
        .el-tabs__nav-wrap {
            margin-bottom: 0;
            .el-tabs__nav {
                border: none;
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                color: #333;
                background: transparent;
                margin-right: 10px;
                font-weight: 500;
                border-left: none;
                border-bottom: none;
                padding: 0 30px!important;
                border-radius: 10px 10px 0 0;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 10px;
                    width: 0;
                    transition: all .3s;
                }
                &:hover {
                    color: #1122D8;
                }
                &.is-active {
                    color: #1122D8;
                    background: #fff;
                    &:after {
                        left: 30px;
                        height: 4px;
                        width: 30px;
                        background: #1122D8;
                    }
                }
            }
        }
    }
    .el-tabs__content {
        height: 1%;
        flex: 1;
        background: #fff;
        .el-tab-pane {
            height: 100%;
        }
    }
    //学生端红色tab
    &.tab-red {
        .el-tabs__header {
            .el-tabs__nav-wrap {
                .el-tabs__item {
                    &.is-active {
                        color: #FD4446;
                        background: #fff;
                        &:after {
                            left: 30px;
                            height: 4px;
                            width: 30px;
                            background: #FD4446;
                        }
                    }
                }
            }
        }
    }
}

//end 总管理员 tab全屏
//start steps样式覆盖
.steps-wrapper {
    display: flex;
    justify-content: center;
    .step-item {
        width: 329px;
        margin-right: 12px;
        position: relative;
        .step-line {
            width: 271px;
            height: 14px;
            position: absolute;
            //right: 58px;
            left: -281px;
            top: 17px;
            background: url("../../assets/images/step-gray.png") no-repeat;
            background-size: cover;
        }
        .step-text {
            width: 48px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: #DCDCDC;
            border-radius: 50%;
            font-size: 24px;
            color: #fff;
        }
        .step-title {
            margin-left: calc(-50% + 48px);
            margin-right: 50%;
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
        }
        &:first-child {
            .step-line {
                display: none;
            }
        }
        &:last-child {
            width: auto;
            margin-right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .step-title {
                white-space: nowrap;
                margin-left: 0;
                margin-right: 0;
            }
        }
        &.current {
            .step-text {
                background: #1122D8;
            }
            .step-line {
                background-image: url("../../assets/images/step-active.png");
            }
        }
    }
}

//end steps样式覆盖
//start 样式覆盖
.btn-blue {
    color: #fff;
    background: #1122D8;
    border-color: #1122D8;
    &:focus,
    &:hover {
        color: #fff;
        border-color: #2838dc;
        background-color: #2838dc;
    }
}

.btn-red {
    color: #fff;
    background: #FD4446;
    border-color: #FD4446;
    &:focus,
    &:hover {
        color: #fff;
        border-color: #fd5658;
        background-color: #fd5658;
    }
}

.btn-red-line {
    color: #666;
    border-color: #DCDCDC;
    &:focus,
    &:hover {
        color: #FD4446;
        border-color: #FD4446;
        background-color: transparent;
    }
}

.btn-red-line-all {
    color: #FD4446;
    border-color: #FD4446;
    &:focus,
    &:hover {
        color: #EB6547;
        border-color: #EB6547;
        background-color: transparent;
    }
}

.btn-blue-line-all {
    color: #1122D8;
    border-color: #1122D8;
    &:focus,
    &:hover {
        color: #5864e4;
        border-color: #5864e4;
        background-color: transparent;
    }
}

.link-blue {
    &.el-link--default {
        color: #1122D8;
        &:hover {
            color: #5864e4;
        }
    }
}

.link-blue-light {
    &.el-link--default {
        color: #1E63F1;
        &:hover {
            color: #6191f5;
        }
        &.is-disabled {
            &:hover {
                color: #C0C4CC;
            }
        }
    }
}

.el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
    color: #1122D8;
}

.dialog-blue {
    .el-dialog__header {
        background: #1122D8;
        .el-dialog__title {
            color: #fff;
        }
        .el-dialog__headerbtn {
            .el-dialog__close {
                color: #fff;
            }
        }
    }
}

.student-breadcrumb {
    font-size: 14px;
    .el-breadcrumb__item {
        .el-breadcrumb__inner {
            font-weight: 400;
            color: #9A9A9A;
        }
    }
    .el-breadcrumb__item:last-child .el-breadcrumb__inner,
    .el-breadcrumb__inner:hover,
    .el-breadcrumb__inner.is-link:hover {
        color: #9A9A9A;
    }
}

//end 样式覆盖
//start 学生端--运营推广--面包屑样式
.breadcrumb {
    color: #9A9A9A !important;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
}

//分页
.operation-pagination {
    padding: 0;
    margin: 30px 0;
    text-align: right;
    .el-pager {
        li.active {
            color: #FD4446;
        }
    }
    .el-pagination__sizes {
        .el-input__inner {
            color: #333;
        }
    }
    .el-pagination__total {
        color: #333;
    }
    .el-pagination__jump {
        color: #333;
    }
}

//end 学生端
#send-content .w-e-text-container {
    height: 270px !important;
}


/*加个按钮间距*/

.btn-margin {
    margin-left: 10px;
}


/*评委端公共布局 start*/

.pw-score-main {
    display: flex;
    width: 100%;
    height: calc(100% - 40px);
    justify-content: flex-end;
    align-items: flex-start;
}

.pw-page-pagination {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
}


/* 店铺引流 表格选中样式修改 */

.jd-table {
    .el-checkbox__input {
        &.is-checked {
            .el-checkbox__inner {
                background-color: #FD4446;
                border-color: #FD4446;
            }
        }
        &.is-indeterminate {
            .el-checkbox__inner {
                background-color: #FD4446;
                border-color: #FD4446;
            }
        }
        .el-checkbox__inner {
            &:hover {
                border-color: #FD4446;
            }
        }
    }
}
/*关键词列表的分页样式*/
.table-page {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #DCDCDC;
    .el-pagination {
        button:disabled, .btn-next, .btn-prev {
            color: #444444;
            background-color: #DCDCDC;
        }
    }
    .el-pager li {
        &.active {
            color: #000000;
            font-weight: bold;
        }
        background-color: #DCDCDC;
        color: #9A9A9A;
    }
}
.main-content {
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    width: 100%;
    height: 100%;
    padding: 30px;
}
.deep-blue-confirm {
    background-color: #1122D8 !important;
    border-color: #1122D8 !important;
}
.import-dialog {
    .el-dialog__header {
        background-color: #1122D8;
        color: #FFF;
        .el-dialog__title {
            color: #FFF;
        }
        .el-dialog__headerbtn {
            .el-dialog__close {
                color: #FFF;
            }
        }
    }
}


/*评委端公共布局 end*/

.zIndex9999 {
    z-index: 9999!important;
}

.tox-statusbar__branding {
    display: none;
}

.customTable {
    color: #343441;
    &:before {
        content: none;
    }
    th {
        height: 60px;
        color: #343441;
        font-weight: 400;
        font-size: 14px;
        &.el-table__cell {
            background-color: #F2F3FA;
        }
        &:first-child {
            &>.cell {
                padding-left: 120px;
            }
        }
    }
    th.el-table__cell.is-leaf {
        border-bottom: none;
    }
    td.el-table__cell {
        border-bottom: 4px solid #f7f8fc;
    }
    td {
        height: 80px;
        &:first-child {
            &>.cell {
                padding-left: 120px;
            }
        }
    }
    &.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
        background-color: #f7f8fc;
    }
}
.number-left.el-input-number .el-input__inner {
    text-align: left;
}

.time-picker {
    // display: none;
    .el-time-spinner{
        .el-time-spinner__wrapper {
            width: 50%;
        }
        .el-time-spinner__wrapper:first-child {
            display: none;
        }
    }
}
::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: #ebeef5;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #ccc;
  }
  ::-webkit-scrollbar-track{
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
  }
  //.el-pagination.is-background .el-pager li:not(.disabled).active {
  //  background-color: #1222d8;
  //}
  //.el-button--primary {
  //  background-color: #1222d8;
  //  border-color: #1222d8
  //}