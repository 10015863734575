
.operation-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .container {
        // height: 100%;
        flex: 1;
        background: #fff;
        overflow: hidden;
    }
}
