
.admin-container {
    background: #f2f2f2;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .header-module {
        height: 60px;
        display: flex;
        .header-logo {
            width: 260px;
            color: #fff;
            background: #1122D8;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            line-height: 60px;
        }
        .header-right {
            width: 1%;
            flex: 1;
            background: #fff;
            box-shadow:0 0 16px 0 rgba(62,58,234,0.1);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 20px;
            .user-info {
                display: flex;
                align-items: center;
                .user-avatar {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: 50%;
                    border: 1px solid #eee;
                    box-sizing: border-box;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .user-name {
                    margin-left: 6px;
                }
            }
            .exit-btn {
                margin-left: 24px;
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: all .3s;
                .iconfont {
                    font-size: 20px;
                }
                span {
                    margin-left: 6px;
                }
                &:hover {
                    color: #1122D8;
                }
            }
        }
    }
    .container-module {
        height: 1%;
        flex: 1;
        display: flex;
        .left-wrapper {
            width: 260px;
            background: #fff;
            box-shadow: 0 0 16px 0 rgba(62,58,234,0.1);
            .left-menu {
                .el-menu-item {
                    height: 60px;
                    line-height: 60px;
                    padding: 0!important;
                    .nav-item {
                        height: 100%;
                        color: #878994;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 0 40px;
                        position: relative;
                        transition: all .3s;
                        .iconfont {
                            color: #CECDFD;
                            font-size: 20px;
                            margin-right: 20px;
                            transition: all .3s;
                        }
                    }
                    &:focus, &:hover {
                        background-color: transparent;
                        .nav-item {
                            color: #0200D8;
                            .iconfont {
                                color: #0200D8;
                            }
                        }
                    }
                    &.is-active {
                        background: #F2F3FA;
                        .nav-item {
                            color: #0200D8;
                            &:before {
                                content: '';
                                width: 4px;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background: #1122D8;
                            }
                            .iconfont {
                                color: #0200D8;
                            }
                        }
                    }
                }
            }
        }
        /deep/ .right-wrapper {
            width: 1%;
            flex: 1;
            & > .el-scrollbar__wrap {
                overflow-x: hidden;
                & > .el-scrollbar__view {
                    height: 100%;
                    min-width: 900px;
                    .right-wrapper-content {
                        padding: 20px;
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}
